import React, { useEffect, useState, useRef } from "react";
import { Col, Input, Row, Table, Tooltip } from "antd";

import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Modal, Space } from "antd";

import ReactToPrint from "react-to-print";

// import { useRouter } from "next/router";
// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
import HeadingBack from "../HeadingBack";
import { useNavigate } from "react-router-dom";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
// import HeadingBack from "../../HeadingBack";

function ServiceEnqueryListComponent() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = navigate;
  const searchParams = new URLSearchParams(location.search);
  const pagev = searchParams.get("page");
  const searchData = searchParams.get("search");
  const componentRef = useRef();
  const { confirm } = Modal;

  const page = navigate?.query?.page == null ? 1 : navigate?.query?.page;
  const search = navigate?.query?.search == null ? "" : navigate?.query?.search;

  //   console.log(navigate);
  //   console.log(navigate?.query?.page);
  //   console.log(searchParams);
  //   console.log(page);
  //   console.log(searchData);

  const order_list = useSelector((state) =>
    state?.allapi?.get_email_qui ? state?.allapi?.get_email_qui : {}
  );
  const order_details = useSelector((state) =>
    state?.allapi?.order_details ? state?.allapi?.order_details : {}
  );
  const get_invoice = useSelector((state) =>
    state?.allapi?.get_invoice ? state?.allapi?.get_invoice : {}
  );

  const [pages, setPage] = useState(1);

  const listdata = order_list?.docs ? order_list?.docs : [];

  useEffect(() => {
    if (search) {
      dispatch(allapiAction.getSearchOrdervendor({ page: page, key: search }));
    } else {
      dispatch(allapiAction.getEmailenquiryusernew(page));
    }
    // dispatch(allapiAction.getInvoceDetails());
    return () => {};
  }, [location]);

  const showDeleteConfirm = (e) => {
    confirm({
      title: "Are you sure delete ?",
      icon: <ExclamationCircleFilled />,
      content: "Some descriptions",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        hendleDelete(e);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const hendleDelete = (e) => {
    console.log(e);
    // deleteProduct
    // setShowDelete(false);
    dispatch(allapiAction.getusercertifitedelete({ _id: e, page: page }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };
  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userId, setuserId] = useState("");

  const [statusData, setstatusData] = useState({});
  const [userData, setuserData] = useState({});
  const [statusDataBox, setstatusDataBox] = useState(false);
  const [statusDataBoxView, setstatusDataBoxView] = useState(false);
  const [statusDataBoxReturn, setstatusDataBoxReturn] = useState(false);
  const [productID, setproductID] = useState("");
  const [statusUpTrStatus, setstatusUpTrStatus] = useState("");
  const [statusUpTrId, setstatusUpTrId] = useState("");
  const hendleToViewM = (e) => {
    setstatusDataBox(true);
    setuserData(e);
    setproductID(e);
    dispatch(allapiAction.getuserOrderDetails({ _id: e?._id }));
  };
  const hendleToViewProduct = (e) => {
    setstatusDataBoxView(true);
    setuserData(e);
    setproductID(e);
    dispatch(allapiAction.getuserOrderDetails({ _id: e?._id }));
    // dispatch(allapiAction.getuserOrderDetails({ _id: route?.query?.id }));
  };
  const hendleToViewReturn = (e) => {
    setstatusDataBoxReturn(true);
    // setuserData(e);
    setstatusUpTrId(e);
    // dispatch(allapiAction.getuserOrderDetails({ _id: e?._id }));
  };
  const hendleSubmitReturn = (e) => {
    setstatusDataBoxReturn(false);
    // dispatch(
    //   allapiAction.cancelOrderByAdmin({
    //     _id: statusUpTrId?._id,
    //     user_id: statusUpTrId?.user_id?._id,
    //     description: statusUpTrStatus,
    //     page: page,
    //   })
    // );
    setstatusUpTrStatus("");
    // dispatch(allapiAction.getuserOrderDetails({ _id: e?._id }));
  };

  const shippingCharge = parseInt(order_details?.delivery_charges);
  console.log(order_details?.orderproductsList);

  const totalSubtotalPricegstdd =
    (order_details.sub_total * order_details?.gst) / 100;

  const totalPrice = order_details?.product_price; // Total price inclusive of GST
  const gstRate = order_details?.gst; // GST rate (5%)

  const basePrice = totalPrice / (1 + gstRate / 100);

  console.log("Base Price (Net Amount): $" + basePrice.toFixed(2));

  const basePriceTotal = basePrice * order_details?.itme_count;

  const perProgst = totalPrice - basePrice;
  console.log(perProgst?.toFixed(2));
  console.log(basePriceTotal?.toFixed(2));

  console.log(order_details);
  const totalgst = order_details?.sub_total - basePriceTotal;
  console.log(totalgst?.toFixed(2));

  const totalSubtotalPricegst = totalSubtotalPricegstdd;

  const totalAmount = order_details?.sub_total + shippingCharge;
  // const totalAmount =
  //   order_details?.sub_total + shippingCharge + totalSubtotalPricegstdd;

  const hendleTochangedata = (e) => {
    const { name, value } = e.target;

    setstatusData({ ...statusData, [name]: value });
  };

  const hendleToSubmetUpdate = () => {
    // dispatch(
    //   allapiAction.addFoundByAdmin({
    //     id: userData?._id,
    //     amount: statusData?.amount,

    //     pageNumber: page,
    //   })
    // );

    setstatusDataBox(false);
  };

  const showModal = (e) => {
    console.log(e);
    setuserId(e);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    dispatch(
      allapiAction.getusercertifitedelete({
        _id: userId,
        status: userId,
        // active: 0,
        pageNumber: page,
      })
    );
    setuserId("");
    setIsModalOpen(false);
    return () => {};
  };

  const hendleToChangeStatus = (id, status) => {
    // dispatch(
    //   allapiAction.user_inactive({
    //     _id: id,
    //     status: status,
    //     // active: 0,
    //     pageNumber: page,
    //   })
    // );
    // setuserId("");
    // setIsModalOpen(false);
    return () => {};
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const hendleToStatus = (id, e) => {
    // statusCategory

    // dispatch(allapiAction.statusProduct({ productId: id, status: e }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };

  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
      className: "table_first_col",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
    },
    {
      title: "Image",
      dataIndex: "referId",
      key: "referId",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer coustomNevc"
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {/* {item?.user_id?.fullName} */}
              <img
                src={URL?.API_BASE_URL + item?.productId?.featureImage}
                alt=""
                style={{ width: "70px" }}
              />
              {/* <p> {item?.user_id?.referId}</p> */}
            </span>
          </>
        );
      },
    },
    // {
    //   title: "Certificate No.",
    //   dataIndex: "srno",
    //   key: "srno",
    //   className: "table_action_col",
    // },
    // {
    //   title: "Name",
    //   dataIndex: "name",
    //   key: "name",
    //   className: "table_action_col",
    // },

    // {
    //   title: "Image",
    //   dataIndex: "image",
    //   key: "image",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         <div style={{ width: "100px" }}>
    //           <img
    //             style={{ width: "100%" }}
    //             src={URL?.API_BASE_URL + item?.featureImage}
    //             alt=""
    //           />
    //         </div>
    //       </>
    //     );
    // //   },
    // },

    {
      title: "Service Name",
      dataIndex: "name",
      key: "name",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer coustomNevc"
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.productId?.title}

              {/* <p> {item?.user_id?.referId}</p> */}
            </span>
          </>
        );
      },
    },
    // {
    //   title: "Certificate for ",
    //   dataIndex: "crType",
    //   key: "crType",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         {/* <Tooltip title="Contact"> */}
    //         <span
    //           className="cursor-pointer coustomNevc"
    //           // onClick={() => navigate(`/user-details/${item?.referId}`)}
    //         >
    //           {item?.crType}

    //           {/* <p> {item?.user_id?.referId}</p> */}
    //         </span>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Father",
    //   dataIndex: "fatherName",
    //   key: "fatherName",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         {/* <Tooltip title="Contact"> */}
    //         <span
    //           className="cursor-pointer coustomNevc"
    //           // onClick={() => navigate(`/user-details/${item?.referId}`)}
    //         >
    //           {item?.fatherName}

    //           {/* <p> {item?.user_id?.referId}</p> */}
    //         </span>
    //       </>
    //     );
    //   },
    // },

    // {
    //   title: "Sponsor Code",
    //   dataIndex: "referByuserId",
    //   key: "referByuserId",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         {/* <Tooltip title="Contact"> */}
    //         <span
    //           className="cursor-pointer coustomNevc"
    //           // onClick={() => navigate(`/user-details/${item?.referId}`)}
    //         >
    //           {item?.user_id?.referByuserId?.fullName}

    //           <p> {item?.user_id?.referByuserId?.referId}</p>
    //         </span>
    //       </>
    //     );
    //   },
    // },

    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         {/* <Tooltip title="Contact"> */}
    //         <span
    //           className="cursor-pointer "
    //           // onClick={() => navigate(`/user-details/${item?.referId}`)}
    //         >
    //           {item?.status}
    //         </span>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Address",
    //   dataIndex: "city",
    //   key: "city",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         {/* <Tooltip title="Contact"> */}
    //         <span
    //           className="cursor-pointer "
    //           // onClick={() => navigate(`/user-details/${item?.referId}`)}
    //         >
    //           {item?.city},{item?.state},{item?.pincode}
    //         </span>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Name",
    //   dataIndex: "title",
    //   key: "title",
    // },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         <Tooltip title={item?.status}>
    //           <button
    //             className={
    //               item?.status == "Active"
    //                 ? "success-button ml-3"
    //                 : "delete-button ml-3"
    //             }
    //             onClick={() =>
    //               hendleToStatus(
    //                 item?._id,
    //                 item?.status == "Active" ? "Inactive" : "Active"
    //               )
    //             }
    //           >
    //             {item?.status}
    //           </button>
    //         </Tooltip>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Upline Id",
    //   dataIndex: "referBy",
    //   key: "referBy",
    // },
    // {
    //   title: "Number",
    //   dataIndex: "phone",
    //   key: "phone",
    // },
    // {
    //   title: "Password",
    //   dataIndex: "passwordPlan",
    //   key: "passwordPlan",
    // },
    // {
    //   title: "Wallet",
    //   dataIndex: "balance",
    //   key: "balance",
    // },
    // {
    //   title: "Wallet",
    //   dataIndex: "Wallet",
    //   key: "Wallet",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>

    //         <span
    //           className="cursor-pointer"
    //           // onClick={() => navigate(`/users/contact/${item?.id}`)}
    //         >
    //           {item?.walletId?.closeBal}
    //         </span>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Reffer Code",
    //   dataIndex: "referCode",
    //   key: "referCode",
    // },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer"
              // onClick={() => navigate(`/users/contact/${item?.id}`)}
            >
              {moment(item?.createdAt).format(" DD/MM/YYYY ")}
            </span>
          </>
        );
      },
    },
    //     {
    //       title: "Status",
    //       dataIndex: "status",
    //       key: "status",
    //       className: "table_action_col",
    //       render: (value, item, index) => {

    //         const userRank =
    //         item?.status == '0'
    //           ? 'free'
    //           : item?.status == '1'
    //           ? 'FRANCHISE'
    //           : item?.status == '2'
    //           ? 'BARON'
    //           : item?.status == '3'
    //           ? 'COUNT'
    //           : item?.status == '4'
    //           ? 'MARQUISE'
    //           : item?.status == '4'
    //           ? 'DUKE'
    //           : 'ARCH DUKE'
    //         return (
    //           <>

    // <span
    //               className="cursor-pointer"

    //             >
    //              {userRank}
    //             </span>

    //           </>
    //         );
    //       },
    //     },
    // {
    //   title: "Amount",
    //   dataIndex: "sub_total",
    //   key: "sub_total",
    // },
    // {
    //   title: "Payment Mode",
    //   dataIndex: "payment_mode_name",
    //   key: "payment_mode_name",
    // },
    // {
    //   title: "Delivery status",
    //   dataIndex: "delivery_status",
    //   key: "delivery_status",
    // },
    // {
    //   title: "Order return",
    //   dataIndex: "orderretune",
    //   key: "orderretune",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         <span
    //           className="cursor-pointer"
    //           // onClick={() => navigate(`/users/contact/${item?.id}`)}
    //         >
    //           {item?.orderretune == "true" ? "Yes" : "No"}
    //         </span>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Actions",
    //   dataIndex: "action",
    //   key: "action",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         {/* <Tooltip title="Contact">
    //           <span
    //             className="cursor-pointer"
    //             onClick={() => navigate(`/users/contact/${item?.id}`)}
    //           >
    //             <SvgIcon.contacts className="scale-icon edit-icon" />
    //           </span>
    //         </Tooltip> */}
    //         {/* <Tooltip title="delete">
    //           <button
    //             className="filter-button ml-3"
    //             onClick={() => showModal(item?._id)}
    //           >
    //             Delete
    //           </button>
    //         </Tooltip>
    //         <Tooltip title="Invoice">
    //           <button
    //             className="filter-button ml-3  mt-5"
    //             onClick={() => hendleToViewM(item)}
    //           >
    //             Print
    //           </button>
    //         </Tooltip> */}
    //         {/* <Tooltip title="View">
    //           <button
    //             className="filter-button ml-3  mt-5"
    //             onClick={() => hendleToViewProduct(item)}
    //           >
    //             View
    //           </button>
    //         </Tooltip> */}
    //       </>
    //     );
    //   },
    // },
  ];

  const [searchs, setsearch] = useState("");
  const handleChange = (e) => {
    setsearch(e);
    setPage(1);
    navigate("/seller/service?page=" + 1 + "&search=" + e);
    // if (e) {
    //   dispatch(allapiAction.getuserListSearch({ page: 1, search: e }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(1));

    //   return () => {};
    // }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (e) => {
    setPage(e);

    console.log(location);

    navigate("/accounts/service?page=" + e + "&&search=" + search);

    // if (search) {
    //   dispatch(allapiAction.getuserListSearch({ page: e, search: search }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(e));

    //   return () => {};
    // }
  };

  return (
    <div>
      <Modal
        title="Delete Certificate"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure delete</p>
      </Modal>

      <Modal
        title="Certificate"
        centered
        open={statusDataBox}
        // onOk={() => hendleToSubmetUpdate(false)}
        onCancel={() => setstatusDataBox(false)}
        width={800}
      >
        <div className="container img100 " ref={componentRef}>
          <div className="row">
            <div className="col-12">
              <div className="card text-center certificate mt-25">
                <img
                  src="/img/certificate.png"
                  className="certificate_image"
                  alt="..."
                />
                <div className="card-img-overlay text-center">
                  <div className="cer">
                    <h1 className="card-title cer"></h1>
                    <img src="/img/cer.png" alt="cer"    loading="lazy" />
                    <h6 className="card-text second_txt"></h6>
                  </div>
                  <p className="card-text mt-5 ">
                    THIS CERTIFICATE IS PROUDLY PRESENTED TO
                  </p>
                  <h3 className=" name text-danger">
                    Name :- {userData?.name}
                  </h3>
                  {/* <h5 className="mt-5">
                      <strong>Lorem Ipsum dolor sit amet adipiscing </strong>
                    </h5> */}
                  <p className="achiver_pra">
                    “This certificate is awarded to{" "}
                    <b className="text-capitalize">{userData?.name}</b> in
                    recognition of their remarkable achievements and exceptional
                    contributions to{" "}
                    <b className="text-capitalize">{userData?.crType}</b> on
                    {moment(userData?.createdAt).format(" DD/MM/YYYY ")}. Your
                    hard work, determination, and passion have set a high
                    standard of excellence”
                    <br />
                  </p>

                  <div className="d-flex justify-content-around mt-5 pt-5">
                    {/* <p className="sign">
                        <i>Signature</i>
                      </p> */}
                    <p className="sign">
                      <i>Signature</i>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6"></div>
          <div className="col-6">
            <ReactToPrint
              trigger={() => (
                <Button variant="primary" onClick={hendleDelete}>
                  Print / Download
                </Button>
                // <button className='bg-blue-500 ml-5 text-white font-bold py-2 px-8 rounded shadow border-2 border-blue-500 hover:bg-transparent hover:text-blue-500 transition-all duration-300'>
                //   Print / Download
                // </button>
              )}
              content={() => componentRef.current}
            />
          </div>
        </div>
      </Modal>
      <Modal
        title="Order Details"
        centered
        open={statusDataBoxView}
        onOk={() => setstatusDataBoxView(false)}
        onCancel={() => setstatusDataBoxView(false)}
        width={1200}
      >
        <div className="">
          <div>
            {/* <h6>Name</h6>
                    <p>{roleID?.name}</p> */}
            {/* <div className='productDetailsImg'>
                      <img src={URL.API_BASE_URL + roleID?.featureImage} alt='' />
                    </div> */}
            <div className="row">
              <div className="col-4">
                <h6> id : -</h6>
              </div>
              <div className="col-8">
                <p>{order_details?._id}</p>
              </div>

              <div className="col-4">
                <h6>payment mode name : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.payment_mode_name}</p>
              </div>

              <div className="col-4">
                <h6>sub total : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.sub_total}</p>
              </div>
            </div>
            <hr />
            <h3>user Details</h3>
            <div className="row">
              <div className="col-4">
                <h6> user id : -</h6>
              </div>
              <div className="col-8">
                <p>{order_details?.user_id?._id}</p>
              </div>

              <div className="col-4">
                <h6>user reffer id : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.user_id?.referId}</p>
              </div>

              <div className="col-4">
                <h6>full name : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.user_id?.fullName}</p>
              </div>
              <div className="col-4">
                <h6>Email : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.user_id?.email}</p>
              </div>
            </div>
            <hr />
            <h3>shipping address</h3>
            <div className="row">
              <div className="col-4">
                <h6> name : -</h6>
              </div>
              <div className="col-8">
                <p>{order_details?.address_id?.name}</p>
              </div>

              <div className="col-4">
                <h6>email : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.address_id?.email}</p>
              </div>

              <div className="col-4">
                <h6>phone : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.address_id?.phone}</p>
              </div>
              <div className="col-4">
                <h6>address : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.address_id?.address}</p>
              </div>
              <div className="col-4">
                <h6>city : -</h6>
              </div>
              <div className="col-8">
                <p>{order_details?.address_id?.city}</p>
              </div>
              <div className="col-4">
                <h6>state : -</h6>
              </div>
              <div className="col-8">
                <p>{order_details?.address_id?.state}</p>
              </div>
              <div className="col-4">
                <h6>pincode : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.address_id?.pincode}</p>
              </div>
            </div>

            <hr />
            <h3>Order Product</h3>
            {/* {order_details?.orderproductsList &&
              order_details?.orderproductsList?.map((data, i) => {
                return ( */}
            <div>
              <div className="row">
                <div className="col-3">
                  <h6>sub order id</h6>
                </div>
                <div className="col-3">
                  <p>{order_details?.sub_order_id}</p>
                </div>
                <div className="col-3">
                  <h6>name</h6>
                </div>
                <div className="col-3">
                  <p>{order_details?.product_names}</p>
                </div>
                <div className="col-3">
                  <h6>Color</h6>
                </div>
                <div className="col-3">
                  <p>{order_details?.colorId?.color}</p>
                </div>
                <div className="col-3">
                  <h6>size</h6>
                </div>
                <div className="col-3">
                  <p>{order_details?.sizeId?.size}</p>
                </div>

                <div className="col-3">
                  <h6>product price</h6>
                </div>
                <div className="col-3">
                  <p> ₹ {order_details?.product_price}</p>
                </div>
                <div className="col-3">
                  <h6></h6>
                </div>
                <div className="col-3">
                  <p></p>
                </div>
                <div className="col-3">
                  <h6>item Count</h6>
                </div>
                <div className="col-3">
                  <p>x {order_details?.itme_count}</p>
                </div>
                <div className="col-3">
                  <h6></h6>
                </div>
                <div className="col-3">
                  <p></p>
                </div>
                <div className="col-3">
                  <h6>sub total</h6>
                </div>
                <div className="col-3">
                  <p> ₹ {order_details?.sub_total}</p>
                </div>
                <div className="col-3">
                  <h6></h6>
                </div>
                <div className="col-3">
                  <p></p>
                </div>
                <div className="col-3">
                  <h6>Shipping</h6>
                </div>
                <div className="col-3">
                  <p>₹ {shippingCharge}</p>
                </div>
                <div className="col-3">
                  <h6></h6>
                </div>
                <div className="col-3">
                  <p> </p>
                </div>
                <div className="col-3">
                  <h6>Gst</h6>
                </div>
                <div className="col-3">
                  <p> ₹ {totalSubtotalPricegstdd}</p>
                </div>
                <div className="col-3">
                  <h6></h6>
                </div>
                <div className="col-3">
                  <p> </p>
                </div>
                <div className="col-3">
                  <h6>Grant total</h6>
                </div>
                <div className="col-3">
                  <p> ₹ {totalAmount}</p>
                </div>
                <div className="col-3">
                  <h6>feture image</h6>
                </div>
                <div className="col-3">
                  <div style={{ width: "100px" }}>
                    <img
                      src={
                        URL.API_BASE_URL + order_details?.colorId?.featureImage
                      }
                      alt=""
                      style={{ width: "100%" }}
                    />
                  </div>
                  {/* <p>  {data?.sub_total}</p> */}
                </div>
              </div>

              <hr />
            </div>
            {/* );
              })} */}

            {/* <h6>product Image</h6> */}
            {/* <div className='row'>
                      {roleID?.images &&
                        roleID?.images?.map((data: any, i: any) => {
                          console.log(data)

                          return (
                            <div className='col-3 proMultiImg'>
                              <img src={URL.API_BASE_URL + data?.img} alt='' />
                            </div>
                          )
                        })}
                    </div> */}
          </div>
        </div>
      </Modal>
      <Modal
        title="Return order"
        centered
        open={statusDataBoxReturn}
        onOk={() => hendleSubmitReturn(false)}
        onCancel={() => setstatusDataBoxReturn(false)}
        width={500}
      >
        <div>
          <Row>
            <Col span={24}>Description</Col>
            <Col span={24}>
              <Input
                name="description"
                placeholder="enter description..."
                value={statusUpTrStatus}
                onChange={(e) => {
                  setstatusUpTrStatus(e?.target?.value);
                }}
              />
            </Col>
          </Row>
        </div>
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"Service"}
            subHeading={`Total ${order_list?.totalDocs || 0} Service`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          {/* <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          /> */}
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>

        <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Add">
            <span
              className="jop_btn"
              onClick={() => navigate("/generate-certificate")}
            >
              Add
              {/* <SvgIcon.Add width={40} height={40} className="cursor-pointer" /> */}
            </span>
          </Tooltip>
        </div>
      </div>

      <div className="tableResponsive mt-10">
        <Table
          className={"table antd_table"}
          dataSource={listdata}
          columns={columns}
          pagination={{
            current: parseInt(page),
            pageSize: 10,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total: order_list?.totalDocs ? order_list?.totalDocs : 0,
          }}
        />
      </div>
    </div>
  );
}

export default ServiceEnqueryListComponent;
