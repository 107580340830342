import React, { useEffect, useLayoutEffect, useState } from "react";
import { Image } from "react-bootstrap";
import ReactImageMagnify from "react-image-magnify";
// import Image from "next/image";
// import { URL } from "@/Redux/common/url";
import { useDispatch, useSelector } from "react-redux";
// import { useRouter } from "next/router";
// import { allapiAction } from "@/Redux/common/action";
import Slider from "react-slick";
import { URL } from "../../Redux/common/url";
import { Link, useNavigate, useParams } from "react-router-dom";
import { allapiAction } from "../../Redux/common/action";
import { toast } from "react-toastify";
import CoustomImg from "../CoustomImg/CoustomImg";

import { Button, Col, Input, Modal, Row, Space } from "antd";
// import Link from "next/link";
function SerivceDetailpub({}) {
  //   const data = useParams();
  const dispatch = useDispatch();
  //   const navigate = useNavigate();

  const [post, setpost] = useState({});
  const [reletedProduct, setreletedProduct] = useState([]);

  console.log(post);

  const router = useNavigate();
  const { id } = useParams();
  const data = id;
  const [productImage, setproductImage] = useState([]);
  const [productColor, setproductColor] = useState("");
  const [productSize, setproductSize] = useState({});
  const [productSizeList, setproductSizeList] = useState([]);
  const [shopDetails, setshopDetails] = useState({});
  console.log(post);
  console.log(reletedProduct);
  const recentProduct = reletedProduct ? reletedProduct : [];

  const productDetais = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      slug: id,
    });

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      // body: raw,
      redirect: "follow",
    };

    const bgl = await fetch(
      URL.API_BASE_URL + `${URL.serviceDetails}/${id}`,
      requestOptions
    );
    const bg2 = await fetch(
      URL.API_BASE_URL + `${URL.serviceDetailsReleted}/${id}`,
      requestOptions
    );

    const bg4 = await fetch(
      URL.API_BASE_URL + `${URL.serviceShopDetails}/${id}`,
      requestOptions
    );
    const events = await bgl.json();
    const events2 = await bg2.json();
    const events4 = await bg4.json();
    // const postData = getPostData(slug);
    const postData = events?.data;
    const postData2 = events2?.data;
    const postData4 = events4?.data;
    setpost(postData);
    setreletedProduct(postData2);
    setshopDetails(postData4);
    // if (!events) {
    //   return { notFound: true };
    // }

    // return {
    //   props: {
    //     post: postData,
    //     reletedProduct: postData2,
    //     // postList: postDataList,
    //   },
    // };
  };

  useEffect(() => {
    productDetais();

    return () => {};
  }, [id]);

  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow:
      recentProduct?.length == 1
        ? 1
        : recentProduct?.length > 6
          ? 6
          : recentProduct?.length - 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow:
            recentProduct?.length == 1
              ? 1
              : recentProduct?.length > 4
                ? 4
                : recentProduct?.length - 1,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow:
            recentProduct?.length == 1
              ? 1
              : recentProduct?.length > 3
                ? 3
                : recentProduct?.length - 1,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow:
            recentProduct?.length == 1
              ? 1
              : recentProduct?.length > 2
                ? 2
                : recentProduct?.length - 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const hendleToAddItme = (e, id) => {
    // setactiveCate(e);

    if (productSize?.stock > 0) {
      dispatch(allapiAction.AddCartItme(e, productColor, productSize?._id));
    } else {
      toast.error("stock not available");
    }
  };

  let pageUrl;

  if (typeof window !== "undefined") {
    if (window.location !== undefined) {
      // Outputs: 'https:'

      // Outputs: '/blog/react-get-current-url/'

      pageUrl = window.location.href;
    }
  }

  const [open, setOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  function copy() {
    const el = document.createElement("input");
    el.value = pageUrl;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    // setCopied(true);
    toast.success("Copied!");
  }

  const get_product_details = post;

  // console.log(get_product_details);

  const get_product_detailsm = useSelector((state) =>
    state?.allapi?.get_product_details ? state?.allapi?.get_product_details : {}
  );

  const [dispImg, setDispImg] = useState("");

  useEffect(() => {
    // dispatch(allapiAction.productDetails(id));
    // dispatch(allapiAction.getsubsubcatehome({}));
    // return () => {};
  }, [id]);
  // console.log(get_product_details?.subcolor);
  useEffect(() => {
    const feImg = URL.API_BASE_URL + get_product_details?.featureImage;

    // setproductColor(get_product_details?.subcolor[0]?._id);
    // setproductSize(get_product_details?.subcolor[0]?.productsize[0]);
    // setproductSizeList(get_product_details?.subcolor[0]?.productsize);

    const allImg = get_product_details?.images?.map((data) => {
      return URL.API_BASE_URL + data?.img;
    });

    // console.log(allImg);

    // if (get_product_details?.images !== undefined) {
    const allImgs = allImg?.filter((data) => {
      return !data.match("undefined");
    });

    // const allimgMarge = [feImg, ...allImgs];

    if (allImg) {
      setDispImg(allImg[0]);
      setproductImage(allImg);
    }

    // }
  }, [get_product_details]);

  const [showWhatsappIn, setShowWhatsappIn] = useState(false);
  const handleCloseWhatsappIn = () => setShowWhatsappIn(false);
  const handleOpneWhatsappIn = () => setShowWhatsappIn(true);

  const [showEmailIn, setShowEmailIn] = useState(false);
  const handleCloseEmailIn = () => setShowEmailIn(false);
  const handleOpneEmailIn = () => setShowEmailIn(true);

  const [whatInq, setwhatInq] = useState({ countryCode: "", whatsappNo: "" });
  const [emailInq, setemailInq] = useState({
    country: "",
    customerEmail: "",
    userName: "",
  });

  const hendleToWhatsapp = (e) => {
    const { name, value } = e.target;
    setwhatInq({ ...whatInq, [name]: value });
  };

  const hendleToEmail = (e) => {
    const { name, value } = e.target;
    setemailInq({ ...emailInq, [name]: value });
  };

  // start

  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode1, setCountryCode1] = useState("");
  const [phoneNumber1, setPhoneNumber1] = useState("");

  const [phoneNumber2, setPhoneNumber2] = useState("");
  const [countryCode12, setCountryCode12] = useState("");
  const [phoneNumber12, setPhoneNumber12] = useState("");
  //   const handlePhoneChange = (value) => {
  //     const phone = value || "";
  //     const phoneNumberObj = parsePhoneNumberFromString(phone);
  //     const conCode = phoneNumberObj ? phoneNumberObj.countryCallingCode : "";
  //     const contry = phoneNumberObj ? phoneNumberObj.nationalNumber : "";
  //     setCountryCode1(conCode);
  //     setPhoneNumber1(contry);
  //     setPhoneNumber(value);
  //   };

  //   const handlePhoneChange2 = (value) => {
  //     const phone = value || "";
  //     const phoneNumberObj = parsePhoneNumberFromString(phone);
  //     const conCode = phoneNumberObj ? phoneNumberObj.countryCallingCode : "";
  //     const contry = phoneNumberObj ? phoneNumberObj.nationalNumber : "";
  //     setCountryCode12(conCode);
  //     setPhoneNumber12(contry);
  //     setPhoneNumber2(value);
  //   };

  useEffect(() => {
    // const blogIds = localStorage.getItem("access_name");
    // const blogIdss = blogIds !== null ? blogIds : "{}";
    // const arr = JSON?.parse(blogIdss);
    // const blogIdsnum = localStorage.getItem("access_number");
    // const blogIdssnum = blogIdsnum !== null ? blogIdsnum : "{}";
    // const arrnum = JSON.parse(blogIdssnum);
    // setPhoneNumber("+" + arrnum?.countryCode + arrnum?.whatsappNo);
    // setPhoneNumber2("+" + arr?.countryCode + arr?.whatsappNo);
    // setCountryCode1(arrnum?.countryCode);
    // setPhoneNumber1(arrnum?.whatsappNo);
    // setCountryCode12(arr?.countryCode);
    // setPhoneNumber12(arr?.whatsappNo);
    // setwhatInq({
    //   name: arrnum?.name,
    //   email: arrnum?.email,
    // });
    // setemailInq({
    //   // message: arr?.message,
    //   customerEmail: arr?.Email,
    //   userName: arr?.Name,
    // });
  }, []);

  const urltet = URL.API_BASE_URL_2 + "/product-details/";

  const feImg = URL.API_BASE_URL + get_product_details?.featureImage;

  const [value, setValue] = useState("");

  useEffect(() => {
    if (typeof window !== "undefined") {
      setValue(localStorage.getItem("access_token"));
    } else {
      setValue("");
    }
  }, []);

  const [windowWidth, setWindowWidth] = useState(600);

  // Update window width when the window is resized
  useLayoutEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Determine the width and height based on screen width
  const imageWidth = windowWidth <= 767 ? 150 : 300;
  const imageHeight = windowWidth <= 767 ? 150 : 300;

  const [statusDataBoxReturn, setstatusDataBoxReturn] = useState(false);
  const [enquiryData, setenquiryData] = useState({});

  const onChangeData = (e) => {
    setenquiryData({ ...enquiryData, [e.target.name]: e.target.value });
  };
  const hendletoSubmitEnquiry = async () => {
    if (!enquiryData?.name) {
      toast?.error("please enter your name");
      return;
    }
    if (!enquiryData?.number) {
      toast?.error("please enter your number");
      return;
    }
    if (!enquiryData?.address) {
      toast?.error("please enter your address");
      return;
    }

    const response = await dispatch(
      allapiAction.emailenquirysubmit({
        sellerId: get_product_details?.sellerId?._id,
        sellerName: get_product_details?.sellerId?.displayName,
        productId: get_product_details?._id,
        productName: get_product_details?.title,
        userName: enquiryData?.name,
        address: enquiryData?.address,
        // country,
        // countryCode,
        whatsappNo: enquiryData?.number,
        customerEmail: enquiryData?.email,
        subject: "Inquiry From Product",
        // sellerEmail,
        // query,
      })
    );

    console.log(response);
    if (response?.success) {
      setstatusDataBoxReturn(false);
      setenquiryData({ name: "", email: "", number: "", address: "" });
    }
  };

  return (
    <div className="overflowhidn">
      <div></div>

      <Modal
        title="Enquiry Now !!"
        centered
        open={statusDataBoxReturn}
        onOk={() => hendletoSubmitEnquiry(false)}
        onCancel={() => setstatusDataBoxReturn(false)}
        width={500}
      >
        <div>
          <Row>
            <Col span={24}>Product Name</Col>
            <Col span={24}>
              <Input
                name="name"
                placeholder="enter product name..."
                value={get_product_details?.title}
                // onChange={(e) => {
                //   onChangeData(e);
                // }}
              />
            </Col>
            <Col span={24}>Name</Col>
            <Col span={24}>
              <Input
                name="name"
                placeholder="enter name..."
                value={enquiryData?.name}
                onChange={(e) => {
                  onChangeData(e);
                }}
              />
            </Col>
            <Col span={24}>Number</Col>
            <Col span={24}>
              <Input
                name="number"
                placeholder="enter number..."
                value={enquiryData?.number}
                onChange={(e) => {
                  onChangeData(e);
                }}
              />
            </Col>
            <Col span={24}>Email</Col>
            <Col span={24}>
              <Input
                name="email"
                placeholder="enter email..."
                value={enquiryData?.email}
                onChange={(e) => {
                  onChangeData(e);
                }}
              />
            </Col>
            <Col span={24}>Address</Col>
            <Col span={24}>
              <Input
                name="address"
                placeholder="enter address..."
                value={enquiryData?.address}
                onChange={(e) => {
                  onChangeData(e);
                }}
              />
            </Col>
          </Row>
        </div>
      </Modal>

      <div className="ps-page--product p-25">
        <div className="ps-container">
          <div className="ps-page__container">
            <div className="ps-page__left">
              <div className="ps-product--detail ps-product--fullwidth">
                <div className="ps-product__header">
                  <div className="col-12 col-sm-5" data-vertical="true">
                    <div className="productPage__left">
                      <div className="productPage__displayImageContainer">
                        <div
                          className="productPage__displayImage"
                          // style={{
                          //   backgroundImage: `url(${
                          //     URL.API_BASE_URL + dispImg
                          //   })`,
                          // }}
                        >
                          {/* <div style={{ width: "250px" }}>
                            <img
                              src={dispImg}
                              alt=""
                              style={{ width: "100%" }}
                            />
                          </div> */}

                          <ReactImageMagnify
                            {...{
                              smallImage: {
                                alt: "Wristwatch by Ted Baker London",
                                isFluidWidth: true,
                                src: dispImg,
                                // sizes:
                                //   "(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px",
                              },
                              largeImage: {
                                src: dispImg,
                                width: 1200,
                                height: 1800,
                              },

                              imageClassName: "imgViewS1",

                              enlargedImageStyle: {
                                width: "50px",
                              },
                              lensStyle: {
                                width: "20px",
                                height: "20px",
                              },
                              shouldUsePositiveSpaceLens: true,
                              shouldHideHintAfterFirstActivation: false,
                              // isHintEnabled: true,
                              lensStyle: {
                                backgroundColor: "rgba(0,0,0,.6)",
                              },
                              enlargedImagePosition: "over",
                            }}
                          />
                        </div>
                      </div>
                      <div className="productPage__sideImagesContainer">
                        {productImage &&
                          productImage?.map((item, index) => {
                            return (
                              <div
                                key={index}
                                onClick={() => setDispImg(item)}
                                className={
                                  dispImg == item
                                    ? "sideImageActive"
                                    : "sideImage "
                                }
                              >
                                <Image
                                  src={item}
                                  alt={"ract"}
                                  width={300}
                                  height={250}
                                  // onError={(e) => {
                                  //   e.target.onerror = null;
                                  //   e.target.src =
                                  //     "../assets/imgs/notfound.png";
                                  // }}
                                />
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    {/* <ReactImageMagnify
                      {...{
                        smallImage: {
                          alt: "Wristwatch by Ted Baker London",
                          isFluidWidth: true,
                          src: dispImg,
                          // sizes:
                          //   "(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px",
                        },
                        largeImage: {
                          src: dispImg,
                          width: 1200,
                          height: 1800,
                        },

                        imageClassName: "imgViewS1",

                        enlargedImageStyle: {
                          width: "50px",
                        },
                        lensStyle: {
                          width: "20px",
                          height: "20px",
                        },
                        shouldUsePositiveSpaceLens: true,
                        shouldHideHintAfterFirstActivation: false,
                        // isHintEnabled: true,
                        lensStyle: {
                          backgroundColor: "rgba(0,0,0,.6)",
                        },
                        enlargedImagePosition: "over",
                      }}
                    /> */}
                    {/* <figure>
                      <div className="ps-wrapper">
                        <div className="ps-product__gallery" data-arrow="true">
                          <div className="item">
                            <a href="/img/products/detail/fullwidth/1.jpg">
                              <img
                                src="/img/products/detail/fullwidth/1.jpg"
                                alt=""
                              />
                            </a>
                          </div>
                          <div className="item">
                            <a href="/img/products/detail/fullwidth/2.jpg">
                              <img
                                src="/img/products/detail/fullwidth/2.jpg"
                                alt=""
                              />
                            </a>
                          </div>
                          <div className="item">
                            <a href="/img/products/detail/fullwidth/3.jpg">
                              <img
                                src="/img/products/detail/fullwidth/3.jpg"
                                alt=""
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </figure>
                    <div
                      className="ps-product__variants"
                      data-item="4"
                      data-md="4"
                      data-sm="4"
                      data-arrow="false"
                    >
                      <div className="item">
                        <img
                          src="/img/products/detail/fullwidth/1.jpg"
                          alt=""
                        />
                      </div>
                      <div className="item">
                        <img
                          src="/img/products/detail/fullwidth/2.jpg"
                          alt=""
                        />
                      </div>
                      <div className="item">
                        <img
                          src="/img/products/detail/fullwidth/3.jpg"
                          alt=""
                        />
                      </div>
                    </div> */}
                  </div>
                  <div className="ps-product__info">
                    <h1>{get_product_details?.title}</h1>
                    {/* <div className="ps-product__meta">
                      <p>
                        Brand:<a href="shop-default.html">Sony</a>
                      </p>
                      <div className="ps-product__rating">
                        <select className="ps-rating" data-read-only="true">
                          <option value="1">1</option>
                          <option value="1">2</option>
                          <option value="1">3</option>
                          <option value="1">4</option>
                          <option value="2">5</option>
                        </select>
                        <span>(1 review)</span>
                      </div>
                    </div> */}
                    {productSize?.afterdiscountprice && productSize?.price && (
                      <h4 className="ps-product__price">
                        ₹{productSize?.afterdiscountprice} – ₹
                        <del>{productSize?.price}</del>
                      </h4>
                    )}

                    <div className="ps-product__shopping">
                      {/* <figure>
                        <figcaption>Quantity</figcaption>
                        <div className="form-group--number">
                          <button className="up">
                            <i className="fa fa-plus"></i>
                          </button>
                          <button className="down">
                            <i className="fa fa-minus"></i>
                          </button>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="1"
                          />
                        </div>
                      </figure> */}
                      {/* <a
                        className="ps-btn ps-btn--black"
                        href="#"
                        onClick={() => {
                          hendleToAddItme(get_product_details?._id);
                        }}
                      >
                        Add to cart
                      </a> */}
                      {/* <a className="ps-btn" href="#">
                        Buy Now
                      </a>
                      <div className="ps-product__actions">
                        <a href="#">
                          <i className="icon-heart"></i>
                        </a>
                        <a href="#">
                          <i className="icon-chart-bars"></i>
                        </a>
                      </div> */}
                    </div>

                    <div className="ps-product__specification">
                      <table>
                        <thead></thead>
                        <tbody>
                          <tr>
                            <th>
                              <b>Categories</b>
                            </th>
                            <th> {get_product_details?.categoryId?.name ? get_product_details?.categoryId?.name : "--"}</th>
                          </tr>
                          <tr>
                            <th>
                              <b>Brands</b>
                            </th>
                            <th>
                              {" "}
                              {get_product_details?.brand2
                                ? get_product_details?.brand2 ? get_product_details?.brand2 : "--"
                                : get_product_details?.brandId?.name ? get_product_details?.brandId?.name : "--"}
                            </th>
                          </tr>

                          <tr>
                            <th>
                              <b>SKU</b>
                            </th>
                            <th>
                              {" "}
                              {get_product_details?.sku
                                ? get_product_details?.sku
                                : "--"}
                            </th>
                          </tr>
                          <tr>
                            <th>
                              <b>Verndor</b>
                            </th>
                            <th style={{ color: "rgb(127, 9, 127)" }}>
                              {" "}
                              <b>{shopDetails?.displayName}</b>
                            </th>
                          </tr>
                        </tbody>
                      </table>
                      {/* <a className="report" href="#">
                        Report Abuse
                      </a>
                      <p>
                        <strong>SKU:</strong> SF1133569600-1
                      </p>
                      <p className="categories">
                        <strong> Categories:</strong>
                        <a href="#">
                          {get_product_details?.categoryDetails?.[0]?.name}
                        </a> */}
                      {/* , */}
                      {/* <a href="#"> Refrigerator</a>,
                        <a href="#">Babies & Moms</a> */}
                      {/* </p> */}
                      {/* <p className="tags">
                        <strong> Tags</strong>
                        <a href="#">sofa</a>,<a href="#">technologies</a>,
                        <a href="#">wireless</a>
                      </p> */}
                    </div>

                    <div className="ps-product__shopping">
                      <a
                        className="ps-btn ps-btn--black"
                        href="#"
                        onClick={() => {
                          setstatusDataBoxReturn(true);
                        }}
                      >
                        Enquiry
                      </a>
                    </div>
                    {/* <div className="ps-product__sharing">
                      <a className="facebook" href="#">
                        <i className="fa fa-facebook"></i>
                      </a>
                      <a className="twitter" href="#">
                        <i className="fa fa-twitter"></i>
                      </a>
                      <a className="google" href="#">
                        <i className="fa fa-google-plus"></i>
                      </a>
                      <a className="linkedin" href="#">
                        <i className="fa fa-linkedin"></i>
                      </a>
                      <a className="instagram" href="#">
                        <i className="fa fa-instagram"></i>
                      </a>
                    </div> */}
                  </div>
                </div>
                <div className="ps-product__content ps-tab-root">
                  <ul className="ps-tab-list">
                    <li className="active">
                      <a href="#tab-1">Description</a>
                    </li>
                    {/* <li>
                      <a href="#tab-2">Specification</a>
                    </li>
                    <li>
                      <a href="#tab-3">Vendor</a>
                    </li>
                    <li>
                      <a href="#tab-4">Reviews (1)</a>
                    </li>
                    <li>
                      <a href="#tab-5">Questions and Answers</a>
                    </li>
                    <li>
                      <a href="#tab-6">More Offers</a>
                    </li> */}
                  </ul>
                  <div className="ps-tabs">
                    <div className="ps-tab active" id="tab-1">
                      <div className="ps-document">
                        <div
                          className=""
                          dangerouslySetInnerHTML={{
                            __html: get_product_details?.description,
                          }}
                        ></div>
                        {/* <h5>
                          Embodying the Raw, Wayward Spirit of Rock 'N' Roll
                        </h5>
                        <p>
                          Embodying the raw, wayward spirit of rock ‘n’ roll,
                          the Kilburn portable active stereo speaker takes the
                          unmistakable look and sound of Marshall, unplugs the
                          chords, and takes the show on the road.
                        </p>
                        <p>
                          Weighing in under 7 pounds, the Kilburn is a
                          lightweight piece of vintage styled engineering.
                          Setting the bar as one of the loudest speakers in its
                          class, the Kilburn is a compact, stout-hearted hero
                          with a well-balanced audio which boasts a clear
                          midrange and extended highs for a sound that is both
                          articulate and pronounced. The analogue knobs allow
                          you to fine tune the controls to your personal
                          preferences while the guitar-influenced leather strap
                          enables easy and stylish travel.
                        </p>
                        <img
                          className="mb-30"
                          src="/img/products/detail/content/description.jpg"
                          alt=""
                        />
                        <h5>What do you get</h5>
                        <p>
                          Sound of Marshall, unplugs the chords, and takes the
                          show on the road.
                        </p>
                        <p>
                          Weighing in under 7 pounds, the Kilburn is a
                          lightweight piece of vintage styled engineering.
                          Setting the bar as one of the loudest speakers in its
                          class, the Kilburn is a compact, stout-hearted hero
                          with a well-balanced audio which boasts a clear
                          midrange and extended highs for a sound that is both
                          articulate and pronounced. The analogue knobs allow
                          you to fine tune the controls to your personal
                          preferences while the guitar-influenced leather strap
                          enables easy and stylish travel.
                        </p>
                        <p>
                          The FM radio is perhaps gone for good, the assumption
                          apparently being that the jury has ruled in favor of
                          streaming over the internet. The IR blaster is another
                          feature due for retirement – the S6 had it, then the
                          Note5 didn’t, and now with the S7 the trend is clear.
                        </p>
                        <h5>Perfectly Done</h5>
                        <p>
                          Meanwhile, the IP68 water resistance has improved from
                          the S5, allowing submersion of up to five feet for 30
                          minutes, plus there’s no annoying flap covering the
                          charging port
                        </p>
                        <ul className="pl-0">
                          <li>
                            No FM radio (except for T-Mobile units in the US, so
                            far)
                          </li>
                          <li>No IR blaster</li>
                          <li>No stereo speakers</li>
                        </ul>
                        <p>
                          If you’ve taken the phone for a plunge in the bath,
                          you’ll need to dry the charging port before plugging
                          in. Samsung hasn’t reinvented the wheel with the
                          design of the Galaxy S7, but it didn’t need to. The
                          Gala S6 was an excellently styled device, and the S7
                          has managed to improve on that.
                        </p> */}
                      </div>
                    </div>
                    {/* <div className="ps-tab" id="tab-2">
                      <div className="table-responsive">
                        <table className="table table-bordered ps-table ps-table--specification">
                          <tbody>
                            <tr>
                              <td>Color</td>
                              <td>Black, Gray</td>
                            </tr>
                            <tr>
                              <td>Style</td>
                              <td>Ear Hook</td>
                            </tr>
                            <tr>
                              <td>Wireless</td>
                              <td>Yes</td>
                            </tr>
                            <tr>
                              <td>Dimensions</td>
                              <td>5.5 x 5.5 x 9.5 inches</td>
                            </tr>
                            <tr>
                              <td>Weight</td>
                              <td>6.61 pounds</td>
                            </tr>
                            <tr>
                              <td>Battery Life</td>
                              <td>20 hours</td>
                            </tr>
                            <tr>
                              <td>Bluetooth</td>
                              <td>Yes</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="ps-tab" id="tab-3">
                      <h4>GoPro</h4>
                      <p>
                        Digiworld US, New York’s no.1 online retailer was
                        established in May 2012 with the aim and vision to
                        become the one-stop shop for retail in New York with
                        implementation of best practices both online
                      </p>
                      <a href="#">More Products from gopro</a>
                    </div>
                    <div className="ps-tab" id="tab-4">
                      <div className="row">
                        <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 ">
                          <div className="ps-block--average-rating">
                            <div className="ps-block__header">
                              <h3>4.00</h3>
                              <select className="ps-rating" data-read-only="true">
                                <option value="1">1</option>
                                <option value="1">2</option>
                                <option value="1">3</option>
                                <option value="1">4</option>
                                <option value="2">5</option>
                              </select>
                              <span>1 Review</span>
                            </div>
                            <div className="ps-block__star">
                              <span>5 Star</span>
                              <div className="ps-progress" data-value="100">
                                <span></span>
                              </div>
                              <span>100%</span>
                            </div>
                            <div className="ps-block__star">
                              <span>4 Star</span>
                              <div className="ps-progress" data-value="0">
                                <span></span>
                              </div>
                              <span>0</span>
                            </div>
                            <div className="ps-block__star">
                              <span>3 Star</span>
                              <div className="ps-progress" data-value="0">
                                <span></span>
                              </div>
                              <span>0</span>
                            </div>
                            <div className="ps-block__star">
                              <span>2 Star</span>
                              <div className="ps-progress" data-value="0">
                                <span></span>
                              </div>
                              <span>0</span>
                            </div>
                            <div className="ps-block__star">
                              <span>1 Star</span>
                              <div className="ps-progress" data-value="0">
                                <span></span>
                              </div>
                              <span>0</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12 ">
                          <form
                            className="ps-form--review"
                            action="https://nouthemes.net/html/martfury/index.html"
                            method="get"
                          >
                            <h4>Submit Your Review</h4>
                            <p>
                              Your email address will not be published. Required
                              fields are marked<sup>*</sup>
                            </p>
                            <div className="form-group form-group__rating">
                              <label>Your rating of this product</label>
                              <select className="ps-rating" data-read-only="false">
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                              </select>
                            </div>
                            <div className="form-group">
                              <textarea
                                className="form-control"
                                rows="6"
                                placeholder="Write your review here"
                              ></textarea>
                            </div>
                            <div className="row">
                              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12  ">
                                <div className="form-group">
                                  <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Your Name"
                                  />
                                </div>
                              </div>
                              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12  ">
                                <div className="form-group">
                                  <input
                                    className="form-control"
                                    type="email"
                                    placeholder="Your Email"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-group submit">
                              <button className="ps-btn">Submit Review</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="ps-tab" id="tab-5">
                      <div className="ps-block--questions-answers">
                        <h3>Questions and Answers</h3>
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Have a question? Search for answer?"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="ps-tab active" id="tab-6">
                      <p>Sorry no more offers available</p>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="ps-page__right">
              <aside className="widget widget_product widget_features">
                <div className="row">
                  <div className="col-12">
                    <img
                      width={100}
                      src={URL?.API_BASE_URL + shopDetails?.sellerProfile}
                      alt=""
                    />
                  </div>
                  <div className="col-12">
                    <h3>{shopDetails?.displayName} </h3>
                    <span className="mt-5">
                      {shopDetails?.city}, {shopDetails?.district},{" "}
                      {shopDetails?.state}, {shopDetails?.zip}
                    </span>
                  </div>
                  <div className="col-6">
                    <div
                      style={{
                        backgroundColor: "#7f097f1f",
                        textAlign: "center",
                        height: "80px",
                        paddingTop: "10px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "18px",
                          fontWeight: "700",
                          color: "#7f097f",
                        }}
                      >
                        Followers{" "}
                      </span>
                      <br />
                      <span> {shopDetails?.follower}</span>
                   
                    </div>
                  </div>
                  <div className="col-6">
                    <div
                      style={{
                        backgroundColor: "#7f097f1f",
                        textAlign: "center",
                        height: "80px",
                        paddingTop: "10px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "18px",
                          fontWeight: "700",
                          color: "#7f097f",
                        }}
                      >
                        Likes{" "}
                      </span>
                      <br />
                      <span> {shopDetails?.like}</span>
                   
                    </div>
                  </div>

         
                  <div className="col-12">
                    <button
                      style={{
                        width: "100%",
                        backgroundColor: "#7f097f",
                        color: "#fff",
                        border: "none",
                        padding: "10px 0",
                        fontSize: "18px",
                        fontWeight: "700",
                        borderRadius: "0",
                        marginTop: "10px",
                        transition: "background-color 0.3s, color 0.3s",
                      }}
                      onClick={() => {
                        router("/vendor-store/" + shopDetails?._id);
                      }}
                    >
                      <i
                        className="fa fa-cart-arrow-down icon-bag2"
                        style={{ color: "#ffffff" }}
                      ></i>{" "}
                      Visit Store
                    </button>
                  </div>
                </div>
                 
              </aside>
              
               
            </div> */}
          </div>
          

          <div className="ps-deal-of-day shewBox">
            <div className="ps-container">
              <div className="ps-section__header">
                <div className="ps-block--countdown-deal">
                  <div className="ps-block__left">
                    <b>
                      {" "}
                      <h3>Related Product</h3>
                    </b>
                  </div>
                  
                </div>
                <a href="#">View all</a>
              </div>
              <div className="ps-section__content mt-30">
                {recentProduct?.length > 0 && (
                  <Slider {...settings}>
                    {recentProduct &&
                      recentProduct?.map((data, i) => {
                        return (
                          <div className="recentlostfound" key={i}>
                            <div className="ps-product">
                              <div className="ps-product__thumbnail">
                                <Link to={"/listing/" + data?.pro_sulg}>
                                  {/* <img
                                    src={URL.API_BASE_URL + data?.featureImage}
                                    alt=""
                                  /> */}
                                  <CoustomImg
                                    url={URL.API_BASE_URL + data?.featureImage}
                                    altkey={data?.title}
                                    w1={"100%"}
                                    w2={"100%"}
                                    h1={80}
                                    h2={180}
                                  />
                                </Link>
                                 
                              </div>
                              <div className="ps-product__container">
                                <div className="ps-product__content">
                                  <Link
                                    className="ps-product__title"
                                    to={"/listing/" + data?.pro_sulg}
                                  >
                                    {data?.title}
                                  </Link>

                                  {data?.afterdiscountprice && data?.price && (
                                    <p className="ps-product__price sale">
                                      ₹{data?.afterdiscountprice}{" "}
                                      <del>₹{data?.price} </del>
                                    </p>
                                  )}
                                </div>
                                
                              </div>
                            </div>
                          </div>
                        );
                      })}

                     
                  </Slider>
                )}
              </div>
            </div>
          </div>
           
        </div>
      </div>
    </div>
  );
}

export default SerivceDetailpub;
