// import { allapiAction } from "@/Redux/common/action";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoaderComponent from "../Loader";
import OurWork from "../OurWork/OurWork";
import OurWork2 from "../OurWork/OurWork2";
import Category from "./Category";
import HomeSlider from "./HomeSlider";
import Listing from "./Listing";
import Listors from "./Listors";
import NavigateBtn from "./NavigateBtn";
import Product from "./Product";
import RecentProduct from "./RecentProduct";
import Video from "./Video";
import Winner from "./Winner";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
import axios from "axios";
import Seo from "../Seo/Seo";
import MyYouTubeComponent from "../MyYouTubeComponent/MyYouTubeComponent";
import YouTubeShorts from "../MyYouTubeComponent/YouTubeShorts";
import ShortVideo from "./ShortVideo";
import DonationList from "./DonationList";
import ServiceList from "./ServiceList";
import Aghoreshwara from "./Aghoreshwara";
import HomeBlogList from "./HomeBlogList";
import OurBranch from "./OurBranch";
import OurGallery from "./OurGallery";
import NewImage from "./NewImage";

function HomeComponent() {
  const dispatch = useDispatch();

  const get_marques_List = useSelector((state) =>
    state?.allapi?.get_marques_List ? state?.allapi?.get_marques_List : {}
  );

  const get_home_all_pro = useSelector((state) =>
    state?.allapi?.get_home_all_pro ? state?.allapi?.get_home_all_pro : {}
  );

  const news = get_marques_List?.news ? get_marques_List?.news : [];
  const admq = get_marques_List?.ads ? get_marques_List?.ads : [];
  const about = get_marques_List?.about ? get_marques_List?.about : [];

  useEffect(() => {
    dispatch(allapiAction.gethomePro({}));
    dispatch(allapiAction.getsliderList({ page: "home" }));
    dispatch(allapiAction.getMarqueesList2({ page: "home" }));
  }, []);

  const handleCreateOrder = async () => {
    try {
      const response = await axios.post(
        URL?.API_BASE_URL + "/publicApi/createOrdersq",
        {
          customerMobile: "9636996332",
          amount: "1",
          orderId: "testmrncxavfrrajm",
          redirectUrl: "http://localhost:6001",
          remark1: "test",
          remark2: "testsdt",
        }
      );

      if (response.data.status) {
        window.location.href = response.data.payment_url;
      } else {
        alert("Failed to create order");
      }
    } catch (error) {
      console.error("Error creating order:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <div>
      <Seo
        settitle={"BestBharat"}
        titleSeo={"BestBharat"}
        seoKeyword={"BestBharat"}
        seo_descriptions={"BestBharat"}
        seoconcal={"https://bestbharat.com/"}
        title={"BestBharat"}
      />
      {Object.keys(get_home_all_pro)?.length > 0 ? (
        <div className="hometest">
          {/* <div className="marquee relative">
            <marquee behavior="scroll" direction="left" scrollamount="05">
              {about &&
                about?.map((data, i) => {
                  return <span key={data._id}>{data?.title}</span>;
                })}
            </marquee>
          </div> */}

          {about?.length > 0 && (
            <div className="  ">
              <div className="mark_bg mt-2">
                <div className="headertext">Notification</div>
                <div className="news">
                  <marquee>
                    {about &&
                      about?.map((data, i) => {
                        return <span key={data._id}>{data?.title}</span>;
                      })}
                  </marquee>
                </div>
              </div>
            </div>
          )}

          {/* <button
            onClick={() => {
              handleCreateOrder();
            }}
          >
            pay
          </button> */}
          <OurWork2 homedata={get_home_all_pro} />
          {/* <div className="marquee">
            <marquee behavior="scroll" direction="left" scrollamount="05">
              {about &&
                about?.map((data, i) => {
                  return <span key={data._id}>{data?.title}</span>;
                })}
            </marquee>
          </div> */}

          <div className="">
            <HomeSlider />
          </div>
          {/* <MyYouTubeComponent videoUrl="https://www.youtube.com/watch?v=dQw4w9WgXcQ" /> */}
          {/* <MyYouTubeComponent videoUrl="https://youtube.com/shorts/6StQIQzYr6Y?si=Y_IhCdJm2urVhKRh" /> */}
          {/* <YouTubeShorts videoUrl="https://youtube.com/shorts/6StQIQzYr6Y?si=Y_IhCdJm2urVhKRh" /> */}
          {/* coment only for now */}
          {/* <div className="marquee mt-5">
            <marquee behavior="scroll" direction="left" scrollamount="05">
              {admq &&
                admq?.map((data, i) => {
                  return <span key={data._id}>{data?.title}</span>;
                })}
            </marquee>
          </div> */}
          {/* <NavigateBtn homedata={get_home_all_pro} /> */}
          {/* <Product homedata={get_home_all_pro} /> */}
          <Aghoreshwara />
          <Category homedata={get_home_all_pro} />
          <DonationList homedata={get_home_all_pro} />
          {/* <NewImage  /> */}
          <ShortVideo homedata={get_home_all_pro} />
          <RecentProduct homedata={get_home_all_pro} />
          <ServiceList homedata={get_home_all_pro} />
          {/* <Listing homedata={get_home_all_pro} /> */}

          {/* <Winner homedata={get_home_all_pro} /> */}

          <div className=" ">
            <HomeBlogList homedata={get_home_all_pro} />
            <Video homedata={get_home_all_pro} />
            <OurBranch homedata={get_home_all_pro} />
            <OurGallery homedata={get_home_all_pro} />
            {/* <Listors homedata={get_home_all_pro} /> */}

            <OurWork homedata={get_home_all_pro} />
          </div>
        </div>
      ) : (
        <LoaderComponent />
      )}
      <hr />
    </div>
  );
}

export default HomeComponent;
