// import { URL } from "@/Redux/common/url";
// import Link from "next/link";
import React from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import CoustomImg from "../CoustomImg/CoustomImg";
import { URL } from "../../Redux/common/url";
import { Link } from "react-router-dom";

function ServiceList({ homedata }) {
  const get_home_all_pro = homedata;

  const recentProductv = get_home_all_pro?.Serviceslist
    ? get_home_all_pro?.Serviceslist
    : [];
  // const recentlisting = recentProductv.length < 8 ? recentProductv.length <1 ? [] :[...recentProductv, ...recentProductv?.slice(0, 8 - recentProductv.length)] : recentProductv;
  const recentlisting =
    recentProductv.length < 8
      ? recentProductv.length < 1
        ? []
        : [
            ...recentProductv,
            ...Array(8 - recentProductv.length).fill(
              recentProductv[Math.floor(Math.random() * (recentProductv.length-1))]
            ),
          ]
      : recentProductv;
  const slidecount =
    recentlisting?.length == 1
      ? 1
      : recentlisting?.length > 6
        ? 6
        : recentlisting?.length - 1;

  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow:
      recentlisting?.length == 1
        ? 1
        : recentlisting?.length > 6
          ? 6
          : recentlisting?.length - 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow:
            recentlisting?.length == 1
              ? 1
              : recentlisting?.length > 4
                ? 4
                : recentlisting?.length - 1,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow:
            recentlisting?.length == 1
              ? 1
              : recentlisting?.length > 3
                ? 3
                : recentlisting?.length - 1,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow:
            recentlisting?.length == 1
              ? 1
              : recentlisting?.length > 2
                ? 2
                : recentlisting?.length - 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <div className="ps-deal-of-day shewBox">
        <div className="ps-container">
          <div className="ps-section__header">
            <div className="ps-block--countdown-deal">
              <div className="ps-block__left">
                <h3>Service</h3>
              </div>
            </div>
            <Link to="/listings">View all</Link>
          </div>
          <div className="ps-section__content mt-10">
            {recentlisting?.length > 0 && (
              <Slider {...settings}>
                {recentlisting &&
                  recentlisting?.map((data, i) => {
                    return (
                      <div className="recentlostfound" key={i}>
                        <div className="recentlisting">
                          <div className="ps-product ht-350">
                            <div className="ps-product__thumbnail">
                              <Link to={"/service/" + data?.pro_sulg}>
                                <CoustomImg
                                  url={URL.API_BASE_URL + data?.featureImage}
                                  altkey={data?.title}
                                  w1={200}
                                  w2={300}
                                  h1={250}
                                  h2={280}
                                />
                              </Link>
                            </div>
                            <div className="ps-product__container">
                              <div className="ps-product__content">
                                <Link
                                  className="ps-product__title"
                                  to={"/service/" + data?.pro_sulg}
                                >
                                  {data?.title}
                                </Link>
                                {/* <Link
                                  className="ps-product__vendor"
                                  to={"/vendor-store/" + data?.sellerId?._id}
                                >
                                  {data?.sellerId?.displayName}
                                </Link> */}
                                {/* <p>
                                  <span>
                                    <i>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        width="18"
                                        height="18"
                                      >
                                        <path
                                          d="M18.364 17.364L12 23.7279L5.63604 17.364C2.12132 13.8492 2.12132 8.15076 5.63604 4.63604C9.15076 1.12132 14.8492 1.12132 18.364 4.63604C21.8787 8.15076 21.8787 13.8492 18.364 17.364ZM12 15C14.2091 15 16 13.2091 16 11C16 8.79086 14.2091 7 12 7C9.79086 7 8 8.79086 8 11C8 13.2091 9.79086 15 12 15ZM12 13C10.8954 13 10 12.1046 10 11C10 9.89543 10.8954 9 12 9C13.1046 9 14 9.89543 14 11C14 12.1046 13.1046 13 12 13Z"
                                          fill="rgba(240,187,64,1)"
                                        ></path>
                                      </svg>
                                    </i>
                                  </span>
                                  {data?.sellerId?.district},
                                  {data?.sellerId?.state}
                                </p> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </Slider>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceList;
