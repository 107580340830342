import React from "react";
import {
  CiCircleFilled,
  CiCircleTwoTone,
  CustomerServiceFilled,
  DashboardOutlined,
  FolderAddFilled,
  MoneyCollectFilled,
  OrderedListOutlined,
  QuestionCircleOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import Logo from "./Logo";
// import { ShowMenu } from "../../utils";
// import { useRouter } from "next/router";
import { useBasePath } from "../../hooks/useBasePath";
import { ShowMenu } from "../../utils";
import { useNavigate } from "react-router-dom";
const { Sider } = Layout;

const Sidebar = ({ collapsed }) => {
  const navigate = useNavigate();
  const { openKey, selectKey } = useBasePath();

  return (
    <Sider
      className="layout_sidebar1"
      collapsible
      collapsed={collapsed}
      width={collapsed ? 0 : 200}
      trigger={null}
      style={{ display: collapsed ? "none" : "block" }}
    >
      <Logo collapsed={collapsed} />
      <div className="sidebar_menus">
        <Menu
          mode="inline"
          theme="dark"
          selectedKeys={selectKey}
          defaultOpenKeys={openKey}
          style={{ background: "#191352" }}
          items={[
            {
              key: "dashboard",
              icon: <DashboardOutlined />,
              label: "Dashboard Management",
              onClick: () => {
                navigate("/accounts");
              },
            },
            {
              key: "order",
              icon: <OrderedListOutlined />,
              label: "Order",
              style: { display: ShowMenu(["Order"]) },
              onClick: () => {
                navigate("/accounts/order");
              },
            },
            {
              key: "order-return",
              icon: <OrderedListOutlined />,
              label: "Order Return",
              style: { display: ShowMenu(["Order Return"]) },
              onClick: () => {
                navigate("/accounts/order-return");
              },
            },
            {
              key: "donation",
              icon: <FolderAddFilled />,
              label: "Donation",
              style: { display: ShowMenu(["Donation"]) },
              onClick: () => {
                navigate("/accounts/donation");
              },
            },
            {
              key: "service",
              icon: <CiCircleFilled />,
              label: "Service",
              style: { display: ShowMenu(["Service"]) },
              onClick: () => {
                navigate("/accounts/service");
              },
            },
            // {
            //   key: "apply-jobs",
            //   icon: <CiCircleFilled />,
            //   label: "Applied Jobs",
            //   style: { display: ShowMenu(["Applied Jobs"]) },
            //   onClick: () => {
            //     navigate("/accounts/apply-jobs");
            //   },
            // },
            // {
            //   key: "direct-team",
            //   icon: <TeamOutlined />,
            //   label: "Direct Team",
            //   style: { display: ShowMenu(["Direct Team"]) },
            //   onClick: () => {
            //     navigate("/accounts/direct-team");
            //   },
            // },
            // {
            //   key: "total-team",
            //   icon: <TeamOutlined />,
            //   label: "Total Team",
            //   style: { display: ShowMenu(["Total Team"]) },
            //   onClick: () => {
            //     navigate("/accounts/total-team");
            //   },
            // },
            // {
            //   key: "my-income",
            //   icon: <MoneyCollectFilled />,
            //   label: "My Income",
            //   style: { display: ShowMenu(["My Income"]) },
            //   onClick: () => {
            //     navigate("/accounts/my-income");
            //   },
            // },
            {
              key: "support-ticket",
              icon: <CustomerServiceFilled />,
              label: "Chat Support",
              style: { display: ShowMenu(["Chat Support"]) },
              onClick: () => {
                navigate("/accounts/support-ticket");
              },
            },
          ]}
        />
      </div>
    </Sider>
  );
};

export default Sidebar;
