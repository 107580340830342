import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { allapiAction } from "../../Redux/common/action";
import moment from "moment";
import { URL } from "../../Redux/common/url";
function BlogDetailsPub() {
  const { id } = useParams();

  const navigate = useNavigate();

  const get_blog_category_list = useSelector((state) =>
    state?.allapi?.get_blog_category_list
      ? state?.allapi?.get_blog_category_list
      : []
  );
  const blog_details = useSelector((state) =>
    state?.allapi?.blog_details ? state?.allapi?.blog_details : {}
  );

  console.log(blog_details);

  const get_recent_blog_list = useSelector((state) =>
    state?.allapi?.get_recent_blog_list
      ? state?.allapi?.get_recent_blog_list
      : []
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(allapiAction.getblogcateAllList({}));
    dispatch(allapiAction.getrectentBlog({}));

    return () => {};
  }, []);
  useEffect(() => {
    if (id) {
      dispatch(allapiAction.getBlogDetails({ id }));
    }

    return () => {};
  }, [id]);

  return (
    <div className="blgDtl">
      <div
        class="banner-slider"
        style={{
          backgroundImage:
            "url(https://creatosoftware.com/public/uploads/news-banner-12.jpg)",
        }}
      >
        <div class="bg"></div>
        <div class="bannder-table">
          <div class="banner-text">
            <h1>{blog_details?.title}</h1>
          </div>
        </div>
      </div>

      <div class="blog-one-area pt_60 pb_90">
        <div class="container">
          <div class="row">
            <div class="col-lg-8">
              <div class="single-blog">
                <img
                  src={URL?.API_BASE_URL + blog_details?.feature_image}
                  alt="News Photo"
                />
                <h3>{blog_details?.title}</h3>
                <ul>
                  {/* <li>
                    <i class="fa fa-edit"></i>
                    <a href="https://creatosoftware.com/category/6">Software</a>
                  </li> */}
                  <li>
                    <i class="fa fa-calendar-o"></i>
                    {/* June 08, 2024 */}
                    {moment(blog_details?.createdAt).format(" MMM DD, YYYY")}
                  </li>
                </ul>

                <div
                  className=""
                  dangerouslySetInnerHTML={{
                    __html: blog_details?.description,
                  }}
                ></div>
                {/* <p>
                  Determining which games are legal can vary depending on the
                  jurisdiction and the specific laws and regulations governing
                  gambling, video games, and other forms of entertainment.
                  Generally, games that comply with local laws and regulations
                  regarding gambling, content ratings, and intellectual property
                  rights are considered legal.
                </p>
                <p>
                  <br />
                </p>
                <p>Here are some categories of legal games:</p>
                <p>
                  <br />
                </p>
                <p>
                  1. **Casual Games:** These are typically non-gambling games
                  designed for entertainment purposes, such as puzzle games,
                  arcade games, and simulation games.
                </p>
                <p>
                  <br />
                </p>
                <p>
                  2. **Console and PC Games:** Video games designed for consoles
                  (e.g., PlayStation, Xbox) or personal computers are legal as
                  long as they comply with relevant laws, such as age ratings
                  and content regulations.
                </p>
                <p>
                  <br />
                </p>
                <p>
                  3. **Mobile Games:** Games designed for smartphones and
                  tablets are legal when they comply with local laws and
                  regulations governing mobile app distribution and content.
                </p>
                <p>
                  <br />
                </p>
                <p>
                  4. **Online Games:** Multiplayer games, browser-based games,
                  and online gaming platforms are legal when they adhere to laws
                  related to online gambling, age restrictions, and user
                  privacy.
                </p>
                <p>
                  <br />
                </p>
                <p>
                  5. **Gambling Games:** Certain gambling games are legal in
                  jurisdictions where gambling is regulated and licensed.
                  However, strict regulations often apply, including age
                  restrictions, responsible gambling measures, and licensing
                  requirements.
                </p>
                <p>
                  <br />
                </p>
                <p>
                  It's essential to review the laws and regulations in your
                  specific location to ensure compliance when developing,
                  distributing, or playing games. Additionally, understanding
                  the terms of service and policies of game platforms and app
                  stores is important to avoid legal issues.
                </p>  */}
              </div>

              <div class="comment-form headstyle mt_50">
                <h4>Share This News</h4>
                <div
                  class="a2a_kit a2a_kit_size_32 a2a_default_style"
                  style={{ lineHeight: "32px" }}
                >
                  {/* <a
                    class="a2a_dd"
                    href="https://www.addtoany.com/share#url=https%3A%2F%2Fcreatosoftware.com%2Fnews%2Fview%2F12&amp;title=https%3A%2F%2Fcreatosoftware.com%2Fnews%2Fview%2F12"
                  >
                    <span
                      class="a2a_svg a2a_s__default a2a_s_a2a"
                      style={{ backgroundColor: "rgb(1, 102, 255)" }}
                    >
                      <svg
                        focusable="false"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 32 32"
                      >
                        <g fill="#FFF">
                          <path d="M14 7h4v18h-4z"></path>
                          <path d="M7 14h18v4H7z"></path>
                        </g>
                      </svg>
                    </span>
                    <span
                      class="a2a_label a2a_localize"
                      data-a2a-localize="inner,Share"
                    >
                      Share
                    </span>
                  </a>
                  <a
                    class="a2a_button_facebook"
                    target="_blank"
                    rel="nofollow noopener"
                    href="/#facebook"
                  >
                    <span
                      class="a2a_svg a2a_s__default a2a_s_facebook"
                      style={{ backgroundColor: "rgb(8, 102, 255)" }}
                    >
                      <svg
                        focusable="false"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 32 32"
                      >
                        <path
                          fill="#fff"
                          d="M28 16c0-6.627-5.373-12-12-12S4 9.373 4 16c0 5.628 3.875 10.35 9.101 11.647v-7.98h-2.474V16H13.1v-1.58c0-4.085 1.849-5.978 5.859-5.978.76 0 2.072.15 2.608.298v3.325c-.283-.03-.775-.045-1.386-.045-1.967 0-2.728.745-2.728 2.683V16h3.92l-.673 3.667h-3.247v8.245C23.395 27.195 28 22.135 28 16"
                        ></path>
                      </svg>
                    </span>
                    <span class="a2a_label">Facebook</span>
                  </a> */}
                  {/* <a class="a2a_button_twitter" target="_blank" rel="nofollow noopener" href="/#twitter"><span class="a2a_svg a2a_s__default a2a_s_twitter" style="background-color: rgb(29, 155, 240);"><svg focusable="false" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path fill="#FFF" d="M28 8.557a10 10 0 0 1-2.828.775 4.93 4.93 0 0 0 2.166-2.725 9.7 9.7 0 0 1-3.13 1.194 4.92 4.92 0 0 0-3.593-1.55 4.924 4.924 0 0 0-4.794 6.049c-4.09-.21-7.72-2.17-10.15-5.15a4.94 4.94 0 0 0-.665 2.477c0 1.71.87 3.214 2.19 4.1a5 5 0 0 1-2.23-.616v.06c0 2.39 1.7 4.38 3.952 4.83-.414.115-.85.174-1.297.174q-.476-.001-.928-.086a4.935 4.935 0 0 0 4.6 3.42 9.9 9.9 0 0 1-6.114 2.107q-.597 0-1.175-.068a13.95 13.95 0 0 0 7.55 2.213c9.056 0 14.01-7.507 14.01-14.013q0-.32-.015-.637c.96-.695 1.795-1.56 2.455-2.55z"></path></svg></span><span class="a2a_label">Twitter</span></a>
                    <a class="a2a_button_google_plus"></a>
                    <a class="a2a_button_pinterest" target="_blank" rel="nofollow noopener" href="/#pinterest"><span class="a2a_svg a2a_s__default a2a_s_pinterest" style="background-color: rgb(230, 0, 35);"><svg focusable="false" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path fill="#fff" d="M15.995 4C9.361 4 4 9.37 4 15.995c0 5.084 3.16 9.428 7.622 11.176-.109-.948-.198-2.41.039-3.446.217-.938 1.402-5.963 1.402-5.963s-.356-.72-.356-1.777c0-1.668.968-2.912 2.172-2.912 1.027 0 1.52.77 1.52 1.688 0 1.027-.65 2.567-.996 3.998-.287 1.195.602 2.172 1.777 2.172 2.132 0 3.771-2.25 3.771-5.489 0-2.873-2.063-4.877-5.015-4.877-3.416 0-5.42 2.557-5.42 5.203 0 1.027.395 2.132.888 2.735a.36.36 0 0 1 .08.345c-.09.375-.297 1.195-.336 1.363-.05.217-.178.266-.405.158-1.481-.711-2.409-2.903-2.409-4.66 0-3.781 2.745-7.257 7.928-7.257 4.156 0 7.394 2.962 7.394 6.931 0 4.137-2.606 7.464-6.22 7.464-1.214 0-2.36-.632-2.744-1.383l-.75 2.854c-.267 1.046-.998 2.35-1.491 3.149a12 12 0 0 0 3.554.533C22.629 28 28 22.63 28 16.005 27.99 9.37 22.62 4 15.995 4"></path></svg></span><span class="a2a_label">Pinterest</span></a>
                    <a class="a2a_button_linkedin" target="_blank" rel="nofollow noopener" href="/#linkedin"><span class="a2a_svg a2a_s__default a2a_s_linkedin" style="background-color: rgb(0, 123, 181);"><svg focusable="false" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path fill="#FFF" d="M6.227 12.61h4.19v13.48h-4.19zm2.095-6.7a2.43 2.43 0 0 1 0 4.86c-1.344 0-2.428-1.09-2.428-2.43s1.084-2.43 2.428-2.43m4.72 6.7h4.02v1.84h.058c.56-1.058 1.927-2.176 3.965-2.176 4.238 0 5.02 2.792 5.02 6.42v7.395h-4.183v-6.56c0-1.564-.03-3.574-2.178-3.574-2.18 0-2.514 1.7-2.514 3.46v6.668h-4.187z"></path></svg></span><span class="a2a_label">LinkedIn</span></a>
                    <a class="a2a_button_digg" target="_blank" rel="nofollow noopener" href="/#digg"><span class="a2a_svg a2a_s__default a2a_s_digg" style="background-color: rgb(26, 26, 26);"><svg focusable="false" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path fill="#FFF" d="M7.07 8.122V11.8h-4.2v8.927h6.828V8.122zm0 10.504H5.497V13.9H7.07zm3.678-6.826h2.626v8.927h-2.626zm0-3.678h2.626v2.626h-2.626zm3.677 3.678v8.927h4.2v1.05h-4.2v2.102h6.827V11.8zm4.2 6.826H17.05V13.9h1.577v4.726zm3.677-6.826v8.927h4.202v1.05h-4.202v2.102h6.828V11.8zm4.202 6.826H24.93V13.9h1.574z"></path></svg></span><span class="a2a_label">Digg</span></a>
                    <a class="a2a_button_tumblr" target="_blank" rel="nofollow noopener" href="/#tumblr"><span class="a2a_svg a2a_s__default a2a_s_tumblr" style="background-color: rgb(0, 25, 53);"><svg focusable="false" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path fill="#fff" fill-rule="evenodd" d="M21.633 26.43h-3.52c-3.165 0-5.523-1.615-5.523-5.476v-6.183H9.714v-3.348c3.165-.814 4.489-3.514 4.64-5.852h3.287v5.309h3.835v3.891h-3.834v5.384c0 1.614.822 2.172 2.13 2.172h1.856z" clip-rule="evenodd"></path></svg></span><span class="a2a_label">Tumblr</span></a>
                    <a class="a2a_button_reddit" target="_blank" rel="nofollow noopener" href="/#reddit"><span class="a2a_svg a2a_s__default a2a_s_reddit" style="background-color: rgb(255, 69, 0);"><svg focusable="false" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path fill="#fff" d="M28.543 15.774a2.953 2.953 0 0 0-2.951-2.949 2.88 2.88 0 0 0-1.9.713 14.1 14.1 0 0 0-6.85-2.044l1.38-4.349 3.768.884a2.452 2.452 0 1 0 .24-1.176l-4.274-1a.6.6 0 0 0-.709.4l-1.659 5.224a14.3 14.3 0 0 0-7.316 2.029 2.9 2.9 0 0 0-1.872-.681 2.942 2.942 0 0 0-1.618 5.4 5 5 0 0 0-.062.765c0 4.158 5.037 7.541 11.229 7.541s11.22-3.383 11.22-7.541a5 5 0 0 0-.053-.706 2.96 2.96 0 0 0 1.427-2.51m-18.008 1.88a1.753 1.753 0 0 1 1.73-1.74 1.73 1.73 0 0 1 1.709 1.74 1.71 1.71 0 0 1-1.709 1.711 1.733 1.733 0 0 1-1.73-1.711m9.565 4.968a5.57 5.57 0 0 1-4.081 1.272h-.032a5.58 5.58 0 0 1-4.087-1.272.6.6 0 0 1 .844-.854 4.5 4.5 0 0 0 3.238.927h.032a4.5 4.5 0 0 0 3.237-.927.6.6 0 1 1 .844.854zm-.331-3.256a1.726 1.726 0 1 1 1.709-1.712 1.717 1.717 0 0 1-1.712 1.712z"></path></svg></span><span class="a2a_label">Reddit</span></a>
                    <a class="a2a_button_stumbleupon"></a>*/}
                  <div style={{ clear: "both" }}></div>
                </div>
                <script
                  async=""
                  src="https://static.addtoany.com/menu/page.js"
                ></script>
              </div>

              {/* <div class="comment-form headstyle mt_50">
                <h4>Comment</h4>
                <div class="comment-inner">
                  <div
                    class="fb-comments fb_iframe_widget fb_iframe_widget_fluid_desktop"
                    data-href="https://creatosoftware.com/news/view/12"
                    data-numposts="5"
                    fb-xfbml-state="rendered"
                    fb-iframe-plugin-query="app_id=323620764400430&amp;container_width=730&amp;height=100&amp;href=https%3A%2F%2Fcreatosoftware.com%2Fnews%2Fview%2F12&amp;locale=en_US&amp;numposts=5&amp;sdk=joey&amp;version=v2.10&amp;width=550"
                  >
                    <span
                      style={{
                        verticalAlign: "bottom",
                        width: "550px",
                        height: "209px",
                      }}
                    >
                      <iframe
                        name="f8a7a7f15f7849c12"
                        width="550px"
                        height="100px"
                        data-testid="fb:comments Facebook Social Plugin"
                        title="fb:comments Facebook Social Plugin"
                        frameborder="0"
                        allowtransparency="true"
                        allowfullscreen="true"
                        scrolling="no"
                        allow="encrypted-media"
                        src="https://www.facebook.com/v2.10/plugins/comments.php?app_id=323620764400430&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df07920afc59167043%26domain%3Dcreatosoftware.com%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Fcreatosoftware.com%252Ff6d4dfcd5001bd6ec%26relation%3Dparent.parent&amp;container_width=730&amp;height=100&amp;href=https%3A%2F%2Fcreatosoftware.com%2Fnews%2Fview%2F12&amp;locale=en_US&amp;numposts=5&amp;sdk=joey&amp;version=v2.10&amp;width=550"
                        style={{
                          border: "none",
                          visibility: "visible",
                          width: "550px",
                          height: "209px",
                        }}
                        class=""
                      ></iframe>
                    </span>
                  </div>
                </div>
              </div> */}
            </div>

            <div class="col-sm-4">
              <div class="row">
                {/* <div class="col-12 border ">
                <div class="input-group my-4">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Recipient's username"
                    aria-label="Recipient's username"
                    aria-describedby="button-addon2"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-success"
                      type="button"
                      id="button-addon2"
                    >
                      Button
                    </button>
                  </div>
                </div>
              </div> */}

                <div class="col-12 border py-3 mt-4">
                  <h3>
                    <u>CATEGORIES</u>
                  </h3>

                  {get_blog_category_list &&
                    get_blog_category_list?.map((data, i) => {
                      return <p>&nbsp;{data?.name}</p>;
                    })}
                  {/* <p class="mt-3">&nbsp;Online Digital Marketing</p>
                <p>&nbsp;Software</p> */}
                </div>

                <div class="col-12 border py-3 mt-4">
                  <h3>
                    <u>RECENT POSTS</u>
                  </h3>

                  {get_recent_blog_list &&
                    get_recent_blog_list?.map((data, i) => {
                      return (
                        <div class="row">
                          <div class="col-6 mt-4">
                            <img
                              src={URL?.API_BASE_URL + data?.feature_image}
                              class="img-fluid"
                            />
                          </div>
                          <div class="col-6 mt-4">
                            <p class="m-0 p-0">
                              <b>{data?.title}</b>
                            </p>
                            <p>
                              {" "}
                              {moment(data?.createdAt).format(" MMM DD, YYYY")}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogDetailsPub;
